@import "~antd/dist/antd.css";

.ant-table-body {
  overflow: auto;
}

.kn-btn-img:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
