[data-theme="compact"] .card-container > .ant-tabs-card > .ant-tabs-content,
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 100%;
  margin-top: -16px;
}

[data-theme="compact"]
  .card-container
  > .ant-tabs-card
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #f8f9fa;
  padding: 16px;
}

[data-theme="compact"] .card-container > .ant-tabs-card > .ant-tabs-bar,
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

[data-theme="compact"]
  .card-container
  > .ant-tabs-card
  > .ant-tabs-bar
  .ant-tabs-tab,
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

[data-theme="compact"]
  .card-container
  > .ant-tabs-card
  > .ant-tabs-bar
  .ant-tabs-tab-active,
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #f8f9fa;
  background: #f8f9fa;
  box-shadow: 0 2px 16px 0 rgba(32, 33, 36, 0.07),
    0 26px 38px 0 rgba(32, 33, 36, 0.09);
}
