.nav-main {
  display: flex;
  align-items: center;
  background: white;
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.nav-inner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 30px;
}

.nav-box {
  padding: 3px 6px;
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-box p {
  margin:0;
  padding-left: 5px;
}
